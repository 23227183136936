<div class="impressum title-position ms-hero-bg-primary landing overflow-hidden">
  <div class="container">
    <div class="card h-100 wow fadeInUp">
      <div class="card-body card-body-big">
        <div class="row mt-4">
          <div class="col-12">
            <h1>Impressum</h1>
            <p><br/> <strong> Consiliari GmbH </strong> <br/> Brauerstraße 12 <br/> 76135 Karlsruhe <br/> Telefon: +49
              721 619329 0 <br/> E-Mail: <a href="mailto:info@consiliari.de">info@consiliari.de</a></p>
            <p><strong>Geschäftsführer: <br/></strong>Raphael J.N. Hettich, M. Sc.</p>
            <p><strong>Handelsregister<br/></strong>beim Amtsgericht Mannheim HRB 727046</p>
            <p><strong> Umsatzsteueridentifikationsnummer <br/> </strong> Ust.ID Nr. DE310584978 </p>
            <p><strong> Inhaltlich verantwortlich </strong> gemäß § 5 TMG <br/> Raphael J.N. Hettich, M. Sc.</p>
            <p><strong> Streitschlichtung <br/></strong>Die Europäische Kommission stellt eine Plattform zur
              Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr <br/> Unsere E-Mail-Adresse finden
              Sie oben im Impressum. <br/> Wir sind nicht verpflichtet, an Streitbeilegungsverfahren vor einer
              Verbraucherschlichtungsstelle teilzunehmen.</p>
            <p><strong> <br/> Haftungshinweis: </strong> Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
              Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren
              Betreiber verantwortlich. Alle Markenrechte und Copyrights von auf dieser Seite benutzen Material liegen
              bei deren Urhebern.</p>
            <p><strong> Allgemeine Nutzungshinweise: </strong> Für Angebote, die Ihnen auf unseren Seiten von Partner
              gemacht werden, sind ausschließlich die Partner verantwortlich. In der Handelsbeziehung mit diesen
              Partnern gelten die jeweils betreffenden AGBs der Partner.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

