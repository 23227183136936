import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AppService} from './services/app.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  title = 'OutlookESP';
  isMenuOpen = false;

  constructor() {
  }

  async ngOnInit(): Promise<void> {


  }

}
