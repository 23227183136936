export class EspVersion {

  public created: Date;
  public version: string;
  public features: string[];
  public knownBugs: string[];
  public requirements: string[];
  public changes: string[];

}

export class EspVersionWithDownloadLink extends EspVersion {
  public downloadLink64: string;
  public downloadLink86: string;
}
