export class CompanyUtil {
  public static MakePhoneLink = (phone: string) => 'tel:' + phone.split(/[ -]/).join('');

  public static MakeEmailLinkAntiSpam = (email: string) => 'mailto:' + email + '.antispam';
}


export const ContactConfig = {
  name: 'Consiliari GmbH',
  email: 'info@consiliari.de',
  phone: '+49 721 619329 0',
  util: CompanyUtil
};
