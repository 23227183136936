<div class="position-relative">
  <ng-container *ngIf="(os|async) as osSelect">

    <mat-button-toggle-group>


      <ng-container *ngIf="osSelect === 'Win32'">
        <div class="d-flex dualbuttl">
          <a style="color: white;" [href]="version.downloadLink86"
             rel="noopener noreferrer">
            <mat-button-toggle mat-line>Download 32-Bit Office Version</mat-button-toggle>
          </a>
        </div>
      </ng-container>

      <ng-container *ngIf="osSelect === 'Win64'">
        <a style="color: white;" [href]="version.downloadLink64"
           rel="noopener noreferrer">
          <mat-button-toggle mat-line>Downloads 64-Bit Office Version</mat-button-toggle>
        </a>
      </ng-container>

      <ng-container *ngIf="osSelect !== 'Win64' && osSelect !== 'Win64'">
        <a style="color: white;" routerLink="/download">
          <mat-button-toggle mat-line>zu Download</mat-button-toggle>
        </a>
      </ng-container>


      <a class="dbuttr w-20 border-spacer">
        <mat-button-toggle (click)="clicked.next(!this.clicked.value)">
          <mat-icon mat-list-icon>arrow_drop_down</mat-icon>
        </mat-button-toggle>
      </a>
    </mat-button-toggle-group>

    <ng-container *ngIf="clicked|async">
      <div class="dropdown-content w-100">
        <a class="dropdown-item" routerLink="/download" rel="noopener noreferrer">
          <button class="droplist" mat-button>zur Downloadseite</button>
        </a>
        <a class="dropdown-item" [href]="version.downloadLink86"
           rel="noopener noreferrer">
          <button class="droplist" mat-button>Download 32-Bit Office Version</button>
        </a>
        <a class="dropdown-item" [href]="version.downloadLink64"
           rel="noopener noreferrer">
          <button class="droplist" mat-button>Download 64-Bit Office Version</button>
        </a>
      </div>
    </ng-container>

  </ng-container>
</div>
