<div class="background">
  <div class="container">
    <div class="footer-content p-0 d-flex flex-md-row justify-content-between flex-column w-100">

      <!-- Footer Adressbereich -->
      <div id="adresse" class="align-items-sm-end mb-5 text-left">

        <h4 class="mb-1 font-weight-bold">
          Consiliari
        </h4>
       <!--
        <img src="assets/logo/Consiliari_Logo_Cleaned_WHITE.png" alt="" height="32" class="my-5">
          -->
        <br>

        <div class="d-flex flex-column align-items-center flex-sm-row py-4 py-sm-2">
          <div  class="d-flex align-items-center " >
            <mat-icon class="icon-circle white">home</mat-icon>
          </div>
          <div class="d-flex align-items-center mx-3" ><p class="text-sm-left m-0" style="text-align:center">
            Brauerstr. 12, 76135 Karlsruhe, Germany
          </p></div>
        </div>

        <div class="d-flex flex-column align-items-center flex-sm-row py-4 py-sm-2">
          <div  class="d-flex align-items-center " >
            <mat-icon class="icon-circle white">email</mat-icon>
          </div>
          <div class="d-flex align-items-center mx-3" >
             <span><a onclick="this.href = this.href.replace('.antispam', '')"
                      [href]="company.util.MakeEmailLinkAntiSpam(company.email)">
            <p class="text-sm-left m-0" style="text-align:center">
            {{company.email}} </p></a></span>
          </div>
        </div>

        <div class="d-flex flex-column align-items-center flex-sm-row py-4 py-sm-2">
          <div  class="d-flex align-items-center " >
            <mat-icon class="icon-circle white">phone</mat-icon>
          </div>
          <div class="d-flex align-items-center mx-3" >
             <span><a [href]="company.util.MakePhoneLink(company.phone)">
            <p class="text-sm-left m-0" style="text-align:center">
            {{company.phone}} </p></a></span>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column mb-5 text-md-right text-center">
        <h4 class="mb-1 copyright" style="color: #bdbdbd;">
          Copyright &copy; Consiliari GmbH 2021
        </h4>
        <br>
        <div class="d-flex justify-content-start flex-column ">
          <a class="own-footer-link" [routerLink]="['/imprint']">
            Impressum
          </a>
          <a class="own-footer-link" [routerLink]="['/datenschutz']">
            Datenschutz
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
