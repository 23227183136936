import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent} from './home/home.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ImprintComponent } from './imprint/imprint.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', redirectTo: '', pathMatch: 'full'},
  { path: 'download', component: DownloadsComponent },
  { path: 'imprint', component: ImprintComponent },
  { path: 'datenschutz', component: PrivacyPolicyComponent},
  { path: '**', component: PageNotFoundComponent }  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
