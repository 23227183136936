<div class="filler"></div>
<div class="content">

  <!-- 1. Teil Begrüßungsseite -->

  <div class="container d-flex flex-column justify-content-center w-80 ">
    <mat-card-header><h1>Von der E-Mail-Flut zum aufgeräumten Postfach.</h1></mat-card-header>
    <div class="landing d-flex flex-column flex-lg-row">
      <div mat-line class="big-lines flex-column  pr-sm-4 px-3">
        <mat-card-header class="my-5"><h3>Mit Machine Learning Outlook aufräumen</h3></mat-card-header>
        <mat-card-header>Schnell das Postfach leeren - zuverlässig, KI-unterstützt und
          benutzerfreundlich.
        </mat-card-header>

        <div class="d-flex justify-content-center flex-column w-100 my-5" *ngIf="firstVersion|async as version">
            <app-version-download-button [version]="version"></app-version-download-button>

          <!-- Button trigger modal -->
          <a class="version-btn text-white  my-3 d-flex justify-content-center" (click)="isOpen = !isOpen"
             cdkOverlayOrigin #trigger="cdkOverlayOrigin">
            Wie finde ich meine Office-Version? </a>
          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="isOpen"
          >
            <div class="dialog justify-content-center align-items-center" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                                aria-hidden="true" (click)="isOpen = !isOpen">

              <div class="modal-area content container d-flex flex-column justify-content-center align-items-center my-5 pb-5">
                <div class="d-flex justify-content-end align-items-end w-100">
                <mat-icon class="text-white">clear</mat-icon>
              </div>
              <h2 mat-dialog-title class="text-white" >Install Wie finde ich meine Office-Version?</h2>
              <div class="modal-body container">
                <div>
                  <img src="../../assets/Screenshot/OutlookVersion00.png" class="big-pic mb-3 mh" height="auto" width="100%"/>
                  <h5>1. Klicken Sie bei Ihrer Standartansicht auf "Datei" oben links</h5>
                </div>
                <div>
                  <img src="../../assets/Screenshot/OutlookVersion01.png" class="big-pic mb-3 mh" height="auto" width="100%"/>
                  <h5>2. Klicken Sie auf "Office-Konto" unten Links</h5>
                </div>
                <div>
                  <img src="../../assets/Screenshot/OutlookVersion02.png" class="big-pic mb-3" height="auto" width="100%"/>
                  <h5>3. Klicken Sie auf "Info zu Outlook" rechts unten</h5>
                </div>
                <div>
                  <img src="../../assets/Screenshot/OutlookVersion03.png" class="big-pic mb-3 mh" height="auto" width="100%"/>
                  <h5>4. Die Outlook-Version steht am Ende der ersten Zeile</h5>
                </div>
              </div>
            </div>
            </div>
          </ng-template>

        </div>
      </div>

      <div mat-line class="big-Pic h-100 justify-content-center pl-sm-2 p-3">
        <img src="../../assets/Screenshot/OutlookPlugIn01.png" class="pictureB d-flex" height="auto" width="100%" alt="E-Mail Sorting Plugin Preview"/>
      </div>
    </div>


    <!-- 2. Text-Content -->
    <mat-card class="info-area w-100 p-0">
      <div class="about w-100 pt-5 p-3 p-sm-5">
        <mat-card-title><h4>E-Mail Sorting Plugin</h4></mat-card-title>
        <p class="text-justify info-text ">
          <br>
          Mit diesem Plug-in werden Nachrichten einer überlaufenen Inbox mittels Machine Learning in angemessene Ordner
          zugeordnet.
          Gerade im Arbeitsleben können sich die Nachrichten in Outlook sehr schnell häufen und es kann schwer fallen,
          zu erkennen, welche Mails priorität haben oder unwichtig sind.
          <br><br>

          Durch Deep Learning findet dieses Plugin Muster von bereits zugewiesenen Mails in vorhandenen Ordnern
          und kann somit Gesetzmäßigkeiten erkennen. Dieses Plug-in visualisiert
          Nachrichten der Inbox, den Inhalt einer ausgewählten Mail, die zugehörige Konversation
          und die wahrscheinlichsten Ordner. Die angezeigte Ordnerauswahl wird von der KI berechnet und die Präzision
          wird
          prozentual veranschaulicht.
          Manuell kann über eine Suchleiste nach
          vorhandenen Ordnern gesucht werden, oder direkt bequem ein neuer
          Ordner lokal erstellt werden. <br>
          Dadurch können Mails aus Ihrer Inbox zuverlässig einem angemessenen
          Ordner zugewiesen werden.
          <br><br>

          Die integrierte Keyboard-Only Steuerung sorgt für eine bedienfreundliche und effiziente
          Benutzererfahrung, die von Ihnen vollständig individualisiert werden kann.
          <br><br>
          Einem freien Kopf und mehr sinnvoller Zeit steht mit unserer Unterstützung nichts mehr im weg.
          <br><br>
        </p>
      </div>

      <mat-divider></mat-divider>

      <div class="features w-100 p-5">
        <mat-card-title><h4>Features</h4></mat-card-title>
        <div class="feature-list d-flex flex-column pt-3 px-sm-4">

          <div class="d-flex flex-column align-items-center flex-sm-row py-4 py-sm-2">
            <div>
              <mat-icon class="icon-circle">check</mat-icon>
            </div>
            <div><p class="py-2 pt-sm-0 text-sm-left" style="text-align:center">Hohe Funktionalität mit geringem
              Aufwand</p></div>
          </div>

          <div class="d-flex flex-column align-items-center flex-sm-row py-4 py-sm-2">
            <div>
              <mat-icon class="icon-circle">school</mat-icon>
            </div>
            <div><p class="py-2 pt-sm-0 text-sm-left" style="text-align:center">
              Gezielte Mustererkennung der Datensätze Ihrer Mailbox durch Machine Learning
            </p></div>
          </div>

          <div class="d-flex flex-column align-items-center flex-sm-row py-4 py-sm-2">
            <div>
              <mat-icon class="icon-circle">video_label</mat-icon>
            </div>
            <div><p class="py-2 pt-sm-0 text-sm-left" style="text-align:center">
              Intuitive Oberfläche im vertrauten Outlook-Design
            </p></div>
          </div>

          <div class="d-flex flex-column align-items-center flex-sm-row py-4 py-sm-2">
            <div>
              <mat-icon class="icon-circle">settings</mat-icon>
            </div>
            <div><p class="py-2 pt-sm-0 text-sm-left" style="text-align:center">
              Vollständig individualisier- und anpassbar
            </p></div>
          </div>

          <div class="d-flex flex-column align-items-center flex-sm-row py-4 py-sm-2">
            <div>
              <mat-icon class="icon-circle">keyboard</mat-icon>
            </div>
            <div><p class="py-2 pt-sm-0 text-sm-left" style="text-align:center">
              Keyboard-Only Steuerung möglich</p></div>
          </div>

          <div class="d-flex flex-column align-items-center flex-sm-row py-4 py-sm-2">
            <div>
              <mat-icon class="icon-circle">pageview</mat-icon>
            </div>
            <div><p class="py-2 pt-sm-0 text-sm-left" style="text-align:center">
              Visualisierte Präzision der Ordnerwahrscheinlichkeit
            </p></div>
          </div>

        </div>
      </div>
    </mat-card>


    <div class="pic-area my-5 w-100 flex-wrap">

      <mat-card-header><h3>Sceenshots</h3></mat-card-header>
      <div class="d-flex flex-row w-100 py-5 px-1">

        <div class="d-flex arrowPic">
          <button mat-icon-button class="pic-button" (click)="valuePic = this.valuePic - 1" *ngIf="valuePic != 1">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
        </div>

        <div class="info-area big-pic w-100 h-100">
          <div>
            <!--
            <div class="discriptionPic d-flex justify-content-center align-items-center px-5 text-white">

              {{textPic}}
          </div>
          -->
            <img src="../../assets/Screenshot/OutlookPlugIn0{{valuePic}}.png"
                 height="auto" width="100%" alt="Screenshot"/>

          </div>
          <!--
          <div *ngIf="valuePic == 2">
            <img src="../../assets/Screenshot/OutlookDemo02.png" height="auto" width="100%"/>
          </div>
          <div *ngIf="valuePic == 3">
            <img src="../../assets/Screenshot/OutlookDemo03.png" height="auto" width="100%"/>
          </div>

          <div *ngIf="valuePic == 4">
            <img src="../../assets/Screenshot/04OutlookPlugin.jpg" height="auto" width="100%"/>
          </div>
          <div *ngIf="valuePic == 5">
            <img src="../../assets/Screenshot/05OutlookPlugin.jpg" height="auto" width="100%"/>
          </div>
          -->
        </div>

        <div class="d-flex arrowPic">
          <button mat-icon-button class="pic-button" (click)="valuePic = this.valuePic + 1" *ngIf="valuePic != 8">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </div>

      <div class=" d-flex flex-column justify-content-center align-items-center px-5 text-white">
        <h5 class="text-white mb-5 ">
          {{screenShot.all[valuePic-1].title}}</h5>
        {{screenShot.all[valuePic-1].text}}
      </div>

      <div class="d-sm-block d-none">
      <div class="pic-collection d-grid align-items-center w-100 h-100 my-5">
        <div *ngFor="let shot of screenShot.all">
        <h6 class="text-white mb-2 h-100 d-md-block d-none">{{shot.title}}</h6>
        <div class="gridcard">
          <button mat-button class="info-area imbu" (click)="valuePic = shot.value;">
            <img src="../../assets/Screenshot/OutlookPlugIn0{{shot.value}}.png" height="auto" width="100%" alt="Screenshot"/></button>
        </div>
        </div>

        <!--
    <a class="gridcard">
      <button mat-button class="info-area imbu" (click)="valuePic=1">
        <img src="../../assets/Screenshot/OutlookDemo01.png" height="auto" width="100%"/></button>
    </a>
    <div class="space"></div>
    <a class="gridcard">
      <button mat-button class="info-area imbu" (click)="valuePic=2">
        <img src="../../assets/Screenshot/OutlookDemo02.png" height="auto" width="100%"/></button>
    </a>
    <div class="space"></div>
    <a class="gridcard">
      <button mat-button class="info-area imbu" (click)="valuePic=3">
        <img src="../../assets/Screenshot/OutlookDemo03.png" height="auto" width="100%"/></button>
    </a>

    <div class="space"></div>
    <a class="gridcard">
      <button mat-button class="info-area imbu" (click)="valuePic=4">
        <img src="../../assets/Screenshot/04OutlookPlugin.jpg" height="auto" width="100%"/></button>
    </a>
    <div class="space"></div>
    <a class="gridcard">
      <button mat-button class="info-area imbu" (click)="valuePic=5">
        <img src="../../assets/Screenshot/05OutlookPlugin.jpg" height="auto" width="100%"/></button>
    </a>
    -->
      </div>
      </div>

    </div>

  </div>
</div>

