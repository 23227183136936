<div class="title-position ms-hero-bg-primary landing overflow-hidden">
  <div class="container">
    <div class="card wow fadeInUp">
      <div class="card-body card-body-big">
        <div class="row mt-4">
          <div class="col-12">
            <h1>Datenschutz</h1>
            <p>
              Datenschutzerklärung nach DSGVO
              <br/>
              <br/>
              Consiliari GmbH
              <br/>
              Brauerstraße 12
              <br/>
              76135 Karlsruhe
              <br/>
              <br/>
              <strong>
                Name und Anschrift des für die Verarbeitung Verantwortlichen
              </strong>
              <br/>
              Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der
              Europäischen Union geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem
              Charakter, ist:
              <br/>
              Consiliari GmbH
              <br/>
              vertreten durch den Geschäftsführer Raphael Hettich
              <br/>
              Brauerstraße 12
              <br/>
              76135 Karlsruhe
              <br/>
              Deutschland
              <br/>
              Tel.: +49 721 619329 0
              <br/>
              E-Mail: info@consiliari.de
              <br/>
              Website:
              <a href="http://www.consiliari.de">
                www.consiliari.de
              </a>
            </p>
            <p>

              <br/>
              <strong>
                Name und Anschrift des Datenschutzbeauftragten
              </strong>
              <br/>
              Der Datenschutzbeauftragte des für die Verarbeitung Verantwortlichen ist:
              <br/>
              Raphael Hettich
              <br/>
              Consiliari GmbH
              <br/>
              Brauerstraße 12
              <br/>
              76135 Karlsruhe
              <br/>
              Deutschland
              <br/>
              Tel.: +49 721 619329 0
              <br/>
              E-Mail: info@consiliari.de
              <br/>
              Website:
              <a href="http://www.consiliari.de">
                www.consiliari.de
              </a>
              <br/>
              <br/>
              <strong>
                Allgemeine Datenschutzerklärung
              </strong>
              <br/>
              Durch die Nutzung der Website der Consiliari GmbH / www.consiliari.de erklären Sie sich mit der Erhebung,
              Verarbeitung und Nutzung von Daten gemäß der nachfolgenden Beschreibung einverstanden. Unsere Website kann
              grundsätzlich ohne Registrierung besucht werden. Bei Ihrem Besuch werden Daten wie beispielsweise
              aufgerufene Seiten bzw. Namen der abgerufenen Datei, Datum und Uhrzeit zu statistischen Zwecken auf dem
              Server gespeichert, ohne dass diese Daten unmittelbar auf Ihre Person bezogen werden. Personenbezogene
              Daten, insbesondere Name, Adresse oder E-Mail-Adresse werden auf freiwilliger Basis erhoben. Ohne Ihre
              Einwilligung erfolgt keine Weitergabe der Daten an Dritte.
              <br/>
              <br/>
              <br/>
              <strong>
                Umgang mit personenbezogenen Daten
              </strong>
              <br/>
              Personenbezogene Daten sind Informationen, mit deren Hilfe eine Person bestimmbar ist, also Angaben, die
              zurück zu einer Person verfolgt werden können. Dazu gehören z.B. der Name und Adressdaten, die
              E-Mail-Adresse, die Telefonnummer oder welche Webseiten von jemandem angesehen wurden.
              <br/>
              Personenbezogene Daten werden von dem Anbieter nur dann erhoben, genutzt und weitergegeben, wenn dies
              gesetzlich erlaubt ist oder die Nutzer in die Datenerhebung einwilligen.
            </p>
            <p>
              <strong>
                <br/>
                Geltungsbereich
              </strong>
              <br/>
              Diese Datenschutzerklärung klärt Nutzer über die Art, den Umfang und Zwecke der Erhebung und Verwendung
              personenbezogener Daten durch den verantwortlichen Anbieter Consiliari GmbH auf dieser Website auf.
              <br/>
              Die rechtlichen Grundlagen des Datenschutzes finden sich in der Datenschutzgrundverordnung (DSGVO) und dem
              Telemediengesetz (TMG).
            </p>
            <p>
              <br/>
              <strong>
                Möglichkeiten zur Kontaktaufnahme
              </strong>
              <br/>
              Die Kontaktaufnahme ist durch die bereitgestellten E-Mail-Adressen möglich. Nehmen Sie über einen dieser
              Kanäle Kontakt mit dem für die Verarbeitung Verantwortlichen auf, so werden die von der betroffenen Person
              übermittelten personenbezogenen Daten automatisch gespeichert. Die Speicherung dient allein zu Zwecken der
              Bearbeitung von Verträgen zwischen der Consiliari GmbH und der betroffenen Person zu
              Auftragsdatenverarbeitungen oder der Kontaktaufnahme zur betroffenen Person.
              <br/>
              <br/>
              <strong>
                Erstellung von Logfiles
              </strong>
              <br/>
              Bei jedem Aufruf der Internetseite erfasst die Consiliari GmbH durch ein automatisiertes System Daten und
              Informationen. Diese werden in den Logfiles des Servers gespeichert.
              <br/>
              Folgende Daten können hierbei erhoben werden:
              <br/>
              (1) Informationen über den Browsertyp und die verwendete Version
              <br/>
              (2) Das Betriebssystem des Nutzers
              <br/>
              (3) Den Internet-Service Provider des Nutzers
              <br/>
              (4) Die IP-Adresse des Nutzers
              <br/>
              (5) Datum und Uhrzeit des Zugriffs
              <br/>
              (6) Webseiten, von denen das System des Nutzers auf unsere Internetseite gelangt (Referrer)
              <br/>
              (7) Webseiten, die vom System des Nutzers über unsere Webseite aufgerufen werden
              <br/>
              Die Verarbeitung der Daten dient zur Auslieferung der Inhalte unserer Internetseite, zur Gewährleistung
              der Funktionsfähigkeit unserer informationstechnischen Systeme und der Optimierung unserer Internetseite.
              Die Daten der Logfiles werden dabei stets getrennt von anderen personenbezogenen Daten der Nutzer
              gespeichert.
            </p>
            <p>
              <br/>
              <strong>
                Datenschutzerklärung für Cookies
              </strong>
              <br/>
              Unsere Website verwendet an einigen Stellen so genannte Cookies. Das sind kleine Textdateien, die es
              möglich machen, auf dem Endgerät des Nutzers spezifische, auf den Nutzer bezogene Informationen zu
              speichern, während er die Website nutzt. Cookies ermöglichen es, insbesondere Nutzungshäufigkeit und
              Nutzeranzahl der Seiten zu ermitteln, Verhaltensweisen der Seitennutzung zu analysieren, aber auch unser
              Angebot kundenfreundlicher zu gestalten. Cookies können ggf. auch über das Ende einer Browser-Sitzung
              gespeichert und können bei einem erneuten Seitenbesuch wieder aufgerufen werden. Wenn Sie das nicht
              wünschen, können Sie Ihren Internetbrowser so einstellen, dass er die Annahme von Cookies verweigert.
            </p>
            <p>
              <br/>
              <strong>
                Routinemäßige Löschung und Sperrung von personenbezogenen Daten
              </strong>
              <br/>
              Wir verarbeiten und speichern personenbezogene Daten der betroffenen Person nur solange, wie dies zur
              Erreichung des Speicherungszwecks erforderlich ist. Eine Speicherung kann darüber hinaus dann erfolgen,
              soweit dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen
              oder sonstigen Vorschriften, denen der für die Verarbeitung verantwortliche unterliegt, vorgesehen wurde.
              <br/>
              Sobald der Speicherungszweck entfällt oder eine durch die genannten Vorschriften vorgeschriebene
              Speicherfrist abläuft, werden die personenbezogenen Daten routinemäßig gesperrt oder gelöscht.
            </p>
            <p>
              <br/>
              <strong>
                Dauer der Speicherung personenbezogener Daten
              </strong>
              <br/>
              Wir speichern Daten solange, wie Sie Informationen und Einladungen zu Veranstaltungen erhalten möchten
              oder Sie mit uns in Kontakt bleiben oder kommunizieren möchten. Bei Ausführung von Verträgen zu
              Auftragsdatenverarbeitungen oder bestehen einer Erforderlichkeit für eine Vertragsanbahnung oder die
              Vertragserfüllung werden die Daten für die Dauer der geltenden gesetzlichen Aufbewahrungspflichten
              gespeichert. Nach Ablauf dieser Pflichten werden die Daten gelöscht.
            </p>
            <p>
              <br/>
              <strong>
                Rechtsgrundlage der Verarbeitung
              </strong>
              <br/>
              Der Besuch der Webseite und Kontaktaufnahme sind freiwillig. Mit Besuch dieser Webseite oder
              Kontaktaufnahme willigen Sie der Verarbeitung Ihrer personenbezogenen Daten ein. Ihre Angaben sind zur
              Kontaktaufnahme und Kommunikation mit der Consiliari GmbH erforderlich
              <br/>
              Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person
              einholen, dient Artikel 6 Absatz 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage.
              <br/>
              Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei
              die betroffene Person ist, erforderlich sind, dient Artikel 6 Absatz 1 lit. b DSGVO als Rechtsgrundlage.
              Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich
              sind.
              <br/>
              Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten
              erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das
              erstgenannte Interesse nicht, so dient Artikel 6 Absatz 1 lit. f DSGVO als Rechtsgrundlage für die
              Verarbeitung. Das berechtige Interesse unseres Unternehmens liegt in der Durchführung unserer
              Geschäftstätigkeit.
            </p>
            <p>
              <br/>
              <strong>
                Weitergabe der Daten an Dritte
              </strong>
              <br/>
              Eine Weitergabe der Daten erfolgt nur, soweit es zur Zweckerfüllung erforderlich ist. Wir setzen externe
              Dienstleister zur Datenverarbeitung ein, die uns bei der Durchführung von Marketing-Maßnahmen und der
              Ausführung von Auftragsdatenverarbeitungen unterstützen. Diese Dienstleister verarbeiten Daten nur gemäß
              der DSGVO sowie den Weisungen und unter der Kontrolle von Consiliari GmbH und ausschließlich zu den in
              diesen Datenschutzhinweisen beschriebenen Zwecken.
            </p>
            <p>
              <br/>
              <strong>
                Rechte der betroffenen Person
              </strong>
              <br/>
              Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen
              folgende Rechte gegenüber dem Verantwortlichen zu:
            </p>
            <p>

              <br/>
              <strong>
                Auskunftsrecht
              </strong>
              <br/>
              <br/>
              Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie
              betreffen, von uns verarbeitet werden.
              <br/>
              Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen
              Auskunft verlangen:
              <br/>
              a. die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;
              <br/>
              b. die Kategorien von personenbezogenen Daten, welche verarbeitet werden;
              <br/>
              c. die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden
              personenbezogenen Daten offengelegt wurden oder noch offengelegt werden;
              <br/>
              d. die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete
              Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;
              <br/>
              e. das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten,
              eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts
              gegen diese Verarbeitung;
              <br/>
              f. das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
              <br/>
              g. alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei
              der betroffenen Person erhoben werden;
              <br/>
              Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten
              in ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang
              können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO im Zusammenhang mit der
              Übermittlung unterrichtet zu werden.
            </p>
            <p>

              <br/>
              <strong>
                Recht auf Berichtigung
                <br/>
              </strong>
              <br/>
              Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die
              verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der
              Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
            </p>
            <p>

            </p>
            <p>
              <br/>
              <strong>
                Recht auf Einschränkung der Verarbeitung
                <br/>
              </strong>
              <br/>
              Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden
              personenbezogenen Daten verlangen:
              <br/>
              a. wenn Sie die Richtigkeit, der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem
              Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;
              <br/>
              b. die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und
              stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;
              <br/>
              c. der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt,
              Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
              <br/>
              d. wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben und noch nicht
              feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.
              <br/>
              Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten –
              von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
              Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen
              Person oder aus Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats
              verarbeitet werden.
              <br/>
              Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem
              Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.
            </p>
            <p>
              <br/>
              <strong>
                Recht auf Löschung
              </strong>
            </p>
            <p>
              <br/>
              Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten
              unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet diese Daten unverzüglich zu
              löschen, sofern einer der folgenden Gründe zutrifft:
              <br/>
              a. Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige
              Weise verarbeitet wurden, nicht mehr notwendig.
              <br/>
              b. Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 lit. a oder Art. 9
              Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
              <br/>
              c. Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine
              vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die Sie legen gem. Art. 21 Abs. 2 DSGVO
              Widerspruch gegen die Verarbeitung ein.
              <br/>
              d. Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.
              <br/>
              e. Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen
              Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der
              Verantwortliche unterliegt.
              <br/>
              Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist
              <br/>
              a. zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
              <br/>
              b. zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der
              Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im
              öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen
              übertragen wurde;
              <br/>
              c. aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit.
              h und i sowie Art. 9 Ab. 3 DSGVO;
              <br/>
              d. für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische
              Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das in Abs. 1 genannte
              Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
              beeinträchtigt, oder
              <br/>
              e. zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
            </p>
            <p>

            </p>
            <p>
              <br/>
              <strong>
                Recht auf Unterrichtung
                <br/>
              </strong>
              <br/>
              Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem
              Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden
              personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung
              der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem
              unverhältnismäßigen Aufwand verbunden.
              <br/>
              Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.
            </p>
            <p>

            </p>
            <p>
              <br/>
              <strong>
                Recht auf Datenübertragbarkeit
                <br/>
              </strong>
              <br/>
              Sie haben das das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen
              bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem
              haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den
              Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern
              <br/>
              a. die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO
              oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO beruht und
              <br/>
              b. die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
              <br/>
              In Ausübung dieses Rechts haben Sie ferner das Recht zu erwirken, dass die Sie betreffenden
              personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt
              werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht
              beeinträchtigt werden.
              <br/>
              Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die
              Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
              öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
            </p>
            <p>

              <br/>
              <strong>
                Widerspruchsrecht
                <br/>
              </strong>
              <br/>
              Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e, f DSGVO
              erfolgt, Widerspruch einzulegen.
              <br/>
              Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er
              kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die die Ihre Interessen, Rechte und
              Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
              Rechtsansprüchen.
              <br/>
              Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie
              das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum
              Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher
              Direktwerbung in Verbindung steht.
              <br/>
              Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden
              personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
              <br/>
              Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
              <br/>
              Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den
              Widerruf der Einwilligung wird die Rechtmäßigkeit, der aufgrund der Einwilligung bis zum Widerruf
              erfolgten Verarbeitung nicht berührt.
            </p>
            <p>
              <br/>
              <strong>
                Recht auf Beschwerde bei einer Aufsichtsbehörde
                <br/>
              </strong>
              <br/>
              Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das
              Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts,
              ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die
              Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
              <br/>
              Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den
              Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs
              nach Art. 78 DSGVO.
            </p>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
