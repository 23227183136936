import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EspVersion, EspVersionWithDownloadLink} from '../models/esp-version';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public version = '#{Build.BuildNumber}#';

  constructor(public http: HttpClient) {

    if (this.version === '#{Build.BuildNumber}#') {
      this.version = '1.0.0';
    }

  }

  public async getVersionsAsync(): Promise<{ current: EspVersionWithDownloadLink, versions: EspVersionWithDownloadLink[] }> {
    try {
      const version = await this.http.get<EspVersion[]>('/files/esp-versions.json').toPromise();
      const versions = version.map(x => {

        const r = x as EspVersionWithDownloadLink;

        r.downloadLink64 = `files/ESP_${r.version}_x64.zip`;
        r.downloadLink86 = `files/ESP_${r.version}_x86.zip`;

        return x as EspVersionWithDownloadLink;
      });

      const current = versions[(versions.length - 1)];


      return {current, versions};
    } catch (e) {
      console.error(e);
    }

    return {current: new EspVersionWithDownloadLink(), versions: []};
  }

}
