<body>

  <header>
    <mat-toolbar id="header" class="navbar d-flex position-fixed py-0 ">
      <div class="logotitle d-flex align-items-center" routerLink="/home">
        <img src="../assets/logo/OutlookPlugInLogo.svg" alt="Logo mit img Tag laden" height="60px" width="auto"/>
        <h5 class="w-100">E-Mail Sorting Plugin</h5>
      </div>

      <div class="navbar d-flex justify-content-end py-0">
        <div class="d-none d-sm-flex">
          <div class="nav-item" routerLink="/home">Home</div>
          <div class="nav-item" routerLink="/download">Download</div>
        </div>
        <div class="dropdown d-sm-none">
          <button mat-button class="menu-button" (click)="isMenuOpen = !this.isMenuOpen">
            <mat-icon>{{ isMenuOpen ? 'format_indent_increase' : 'menu' }}</mat-icon>
          </button>

        </div>
      </div>
    </mat-toolbar>
  </header>

  <div class="dropdown-content d-sm-none w-100" *ngIf="isMenuOpen == true">
    <div class="nav-item dropdown-item" routerLink="/home" (click)="isMenuOpen = false">Home</div>
    <div class="nav-item dropdown-item" routerLink="/download" (click)="isMenuOpen = false">Download</div>
  </div>


  <div class="circle1 d-none d-sm-flex position-fixed"></div>
  <div class="circle2 d-none d-sm-flex position-fixed"></div>
  <<!--<div class="circle3 d-flex position-fixed"></div>-->

  <router-outlet class="d-block w-100">
  </router-outlet>
  <app-footer class="d-block w-100 ms-footer"></app-footer>

</body>

