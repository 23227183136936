import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AppService} from '../services/app.service';
import {BehaviorSubject} from 'rxjs';
import {EspVersion, EspVersionWithDownloadLink} from '../models/esp-version';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadsComponent implements OnInit {

  public versions = new BehaviorSubject<EspVersionWithDownloadLink[]>([]);
  public firstVersion = new BehaviorSubject<EspVersionWithDownloadLink>(null);

  panelOpenState = false;

  constructor(public appService: AppService) {
  }

  async ngOnInit(): Promise<void> {


    const res = await this.appService.getVersionsAsync();
    this.versions.next(res.versions.slice().reverse());
    this.firstVersion.next(res.current);

  }

}
