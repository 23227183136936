export class ScreenShots {
  title: string;
  value: number;
  image: string;
  text: string;
}
interface ScreenShotsMap {
  [x: string]: ScreenShots;
}

const ScreenShotsContent: ScreenShotsMap = {
  Screen1: {
    title: 'Standardanwendung',
    value: 1,
    image: 'src/assets/Screenshot/OutlookPlugIn01.png',
    text: 'Die Hauptanwendung nimmt die ausgewählten Mails aus dem Postfach und teilt diese dem angemessenen Ordner zu.' +
      'Zusätzlich bietet diese Anwendung Multi-Account-Support an, die auch unten rechts markiert ist. ' +
      'Die Legende zeigt Markierungen für Zuordnungen, mit hovern über den Ordnern wird der Pfad damit angezeigt' +
      'Diese Wahl ist die Beste für das gesammte Postfach'
  },
  Screen2: {
    title: 'Anwendungseinstellungen',
    value: 2,
    image: 'src/assets/Screenshot/OutlookPlugIn02.png',
    text: 'Die Hauptanwendung bietet über den Einstellungsbutton oben recht die Möglichkeit einer individuellen Anpassung.' +
      'Unter Einstellungen können mit Generelle Einstellungen der Zeitpunkt und der Modus des Plugins, sowie die Trainingszeit gewählt werden.'
  },
  Screen3: {
    title: 'Ordner-Einstellungen',
    value: 3,
    image: 'src/assets/Screenshot/OutlookPlugIn01.png',
    text: 'Die Hauptanwendung bietet über den Einstellungsbutton oben recht die Möglichkeit einer individuellen Anpassung.' +
      'Unter Ordner kann die Ordnerverwaltung einstellt werden, welche Ordnergruppen verwaltet und ignoriert werden können. '
  },
  Screen4: {
    title: 'Tastatur-Einstellungen',
    value: 4,
    image: 'src/assets/Screenshot/OutlookPlugIn04.png',
    text: 'Die Hauptanwendung bietet über den Einstellungsbutton oben recht die Möglichkeit einer individuellen Anpassung.' +
      'Unter Tastenbelegung kann der Benutzer seine eigenen Keyboardsettings bestimmen. '
  },
  Screen5: {
    title: 'Maus-Einstellungen',
    value: 5,
    image: 'src/assets/Screenshot/OutlookPlugIn05.png',
    text: 'Die Hauptanwendung bietet über den Einstellungsbutton oben recht die Möglichkeit einer individuellen Anpassung.' +
      'Unter Maus kann der Benutzer seine Mausfunktionen berwalten. '
  },
  Screen6: {
    title: 'Tastenkürzel',
    value: 6,
    image: 'src/assets/Screenshot/OutlookPlugIn06.png',
    text: 'Die Hauptanwendung verfügt unten rechts über eine Legende, die nicht nur Multi-Account-Support visualisiert, ' +
      'sondern in der Legende auch ein Cheatsheet für Tastenkombinationen und Funktionen anzeigt. Diese sind unter Einstellungen auch' +
      'selbst belegbar und damit komplett individualisierbar'
  },
  Screen7: {
    title: 'QuickSort',
    value: 7,
    image: 'src/assets/Screenshot/OutlookPlugIn07.png',
    text: 'Quicksort ist eine reduzierte Version der Anwendung und damit eine schnellere Variante.' +
      'Als entschlackter Modus können die Nachrichten einfacher und schneller bewegt werden.' +
      'Diese Wahl ist die Beste für schnelle und direktes Sortieren und Zuweisen.'
  },
  Screen8: {
    title: 'Globaler Filter',
    value: 8,
    image: 'src/assets/Screenshot/OutlookPlugIn08.png',
    text: 'Mit dem Globalen Filter kann die gesammte Ordnerstruktur gefiltert werden.' +
      'Hierbei handelt es sich um eine schnelle Suche, die nicht auf die Outlook-Suche basiert.' +
      'Diese Wahl ist die Beste für schnelle und direktes Finden.'
  }
};

export const ScreenShotsConfig = {
  all: [
    ScreenShotsContent.Screen1,
    ScreenShotsContent.Screen2,
    ScreenShotsContent.Screen3,
    ScreenShotsContent.Screen4,
    ScreenShotsContent.Screen5,
    ScreenShotsContent.Screen6,
    ScreenShotsContent.Screen7,
    ScreenShotsContent.Screen8
  ]
};
