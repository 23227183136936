import {Component, Input, OnInit} from '@angular/core';
import {EspVersionWithDownloadLink} from '../../models/esp-version';

@Component({
  selector: 'app-version-view',
  templateUrl: './version-view.component.html',
  styleUrls: ['./version-view.component.scss']
})
export class VersionViewComponent implements OnInit {

  @Input()
  item: EspVersionWithDownloadLink;

  constructor() { }

  ngOnInit(): void {
  }

}
