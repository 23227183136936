<dl class="mt-3 pt-2">

  <dd class="d-flex justify-content-between">
    <p class="d-flex flex-column">
      <strong class="px-1">Version {{item.version}}</strong>
      <span class="px-1">{{item.created|date:'d. MMM yyyy'}}</span>
    </p>

      <app-version-download-button [version]="item"></app-version-download-button>

  </dd>

</dl>
<dl class="dim-bg mb-2 py-2">

  <dd *ngIf="item.features && item.features.length > 0">
    <p><strong>Features: </strong></p>
    <ul>
      <li *ngFor="let feature of item.features">
        <p [innerHTML]="feature"></p>
      </li>
    </ul>
  </dd>

  <dd *ngIf="item.knownBugs && item.knownBugs.length > 0">
    <p><strong>Bekannte Bugs: </strong></p>
    <ul>
      <li *ngFor="let bugs of item.knownBugs">
        <p [innerHTML]="bugs"></p>
      </li>
    </ul>

  </dd>

  <dd *ngIf="item.changes && item.changes.length > 0">
    <p><strong>Änderungen: </strong></p>
    <ul>
      <li *ngFor="let change of item.changes">
        <p [innerHTML]="change"></p>
      </li>
    </ul>
  </dd>

  <dd *ngIf="item.requirements && item.requirements.length > 0">
    <p><strong>Anforderungen: </strong></p>
    <ul>
      <li *ngFor="let requirement of item.requirements">
        <p [innerHTML]="requirement"></p>
      </li>
    </ul>
  </dd>

</dl>
