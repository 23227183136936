

<div class="container content d-flex flex-column align-items-center justify-content-center w-80 ">

  <mat-card class="info-area d-flex flex-column w-100 pt-5 p-3 p-sm-5 justify-content-center align-items-center">

      <mat-card-title><h1>404</h1></mat-card-title>
      <mat-card-title><h4>OOOPPS! Page Not found</h4></mat-card-title>
      <p class="text-justify info-text ">
        Sorry, you entered the wrong Dimension >. <
      </p>

  </mat-card>

</div>
