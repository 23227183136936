import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {EspVersionWithDownloadLink} from '../models/esp-version';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-version-download-button',
  templateUrl: './version-download-button.component.html',
  styleUrls: ['./version-download-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VersionDownloadButtonComponent implements OnInit {

  @Input()
  version: EspVersionWithDownloadLink;
  clicked = new BehaviorSubject<boolean>(false);
  os = new BehaviorSubject<string>(null);

  constructor() { }

  ngOnInit(): void {

    this.os.next(this.getOS());

  }
  getOS(): string {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (windowsPlatforms.indexOf(platform) !== -1 && windowsPlatforms.indexOf('Win32')) {
      os = 'Win32';
    } else if (windowsPlatforms.indexOf(platform) !== -1 && windowsPlatforms.indexOf('Win64')) {
      os = 'Win64';
    } else {
      os = 'notWin';
    }

    return os;
  }


}
