import {ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {EspVersion, EspVersionWithDownloadLink} from '../models/esp-version';
import {AppService} from '../services/app.service';
import {  ScreenShotsConfig, ScreenShots } from './screenshots';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {


  isOpen = false;
  public screenShot: any = ScreenShotsConfig;
  public panelState = 0;
  public valuePic = ScreenShotsConfig.all[0].value;
  //  public titlePic: string = ScreenShotsConfig.all[0].title;
  // public textPic: string = ScreenShotsConfig.all[0].text;

  public firstVersion = new BehaviorSubject<EspVersionWithDownloadLink>(null);


  constructor(public appService: AppService, public dialog: MatDialog) {

  }

  async ngOnInit(): Promise<void> {

    // alert(this.getOS());
    const res = await this.appService.getVersionsAsync();
    this.firstVersion.next(res.current);

  }

}
