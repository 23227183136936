<div class="content container d-flex flex-column justify-content-start w-80 download my-0">

  <!-- 1. Download-Section -->

  <mat-expansion-panel
    class="download-area w-100"
    [expanded]="true"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false">
    <mat-expansion-panel-header class="">
      <mat-panel-title>
        <h4>Downloads</h4>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-container *ngIf="(firstVersion|async) as item">

      <app-version-view class="my-2" [item]="firstVersion|async"></app-version-view>

    </ng-container>
  </mat-expansion-panel>


  <mat-expansion-panel
    class="download-area w-100">
    <mat-expansion-panel-header class="">
      <mat-panel-title>
        <h4>Installationsanleitung</h4>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ol class="mt-3">
      <li mat-line>
        <p *ngIf="(firstVersion|async) as item">
          Bitten downloaden Sie das Plugin <a [href]="item.downloadLink64" target="_blank" class="p-0 m-0">hier.</a>
        </p>
      </li>
      <li mat-line>
        <p>
          Entpacken Sie das Installationspaket und führen Sie "setup.exe" aus.
        </p>
      </li>
      <li mat-line>
        <p>
          Folgen Sie den Installationsanweisungen.
        </p>
      </li>
      <li mat-line>
      <p>
        Emails, die über das Plugin verwaltet werden sollen, müssen lokal auf der Machine gehalten werden. <br>Wie das geht finden Sie<a style="color:#a200ff" [routerLink]="['exchange-local-mails-instruction']" routerLinkActive="active">in dieser Anleitung</a>.
      </p>
    </li>
      <li mat-line>
        <p>
          Starten Sie Outlook 2019 und öffnen Sie das Plugin über die neuen Schaltflächen im Reiter "Start".
        </p>
      </li>
      <li mat-line>
        <p>
          Viel Spaß beim E-Mails sortieren!
        </p>
      </li>
    </ol>
  </mat-expansion-panel>


  <!-- older Versions -->

  <mat-expansion-panel
    class="download-area w-100"
    [expanded]="false"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false">
    <mat-expansion-panel-header class="">
      <mat-panel-title>
        <h4>Ältere Versionen</h4>
      </mat-panel-title>
    </mat-expansion-panel-header>


    <ng-container *ngFor="let item of versions|async">
      <app-version-view [item]="item"></app-version-view>
    </ng-container>

  </mat-expansion-panel>

</div>
